import axios from 'axios'

const REPORTING_SERVICE = process.env.REACT_APP_REPORTING_SERVICE

// Makes POST request to domesday
export const requestContentInventory = async (
  jwt?: string,
  context?: string
) => {
  await axios({
    url: `${REPORTING_SERVICE}/${context}/content_inventory_requests`,
    data: {},
    method: 'post',
    headers: {
      Authorization: 'Bearer ' + jwt,
      'content-type': 'application/json',
    },
  })
}

// Makes GET request to retrieve extant content inventory jobs from domesday
export const getContentInventoryJobs = async (
  jwt?: string,
  context?: string
) => {
  const response = await axios({
    url: `${REPORTING_SERVICE}/${context}/content_inventory_requests`,
    method: 'get',
    headers: { Authorization: 'Bearer ' + jwt },
  })
  return response.data.data
}
