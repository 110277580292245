import React, { useEffect, useState } from 'react'
import {
  GrapheneRoot,
  PrimaryButton,
  Stack,
  Header,
  Paragraph,
} from '@elsevier/graphene'
import '../scss/scrivener-main.scss'
import ContentInventoryJobsList from './ContentInventoryJobsList'
import ModalError from './ModalError'
import {
  getContentInventoryJobs,
  requestContentInventory,
} from '../service/ScrivenerService'
import {
  generateInitialPageData,
  LoadTracker,
  trackRequestClick,
} from '../analytics'
import { useParams } from 'react-router-dom'
import { useFileStatus } from '../hooks/FileStatus'

export default function ScrivenerUI() {
  const [pastJobsData, setPastJobsData] = useState([])
  const [noJobsFound, setNoJobsFound] = useState(false)
  const [errorInfo, setErrorInfo] = useState({ title: '', detail: '' })
  const [errorModalVisible, setErrorModalVisible] = useState(false)

  // Adobe Analytics --> Track Initial page load/visit
  useEffect(() => {
    const loadTracker: any = new LoadTracker()
    loadTracker.startTracking()
    loadTracker.stopTracking()
    window.pageData = generateInitialPageData(loadTracker.loadTime)
    window.pageData.page.loadTime = loadTracker.loadTime
    window.pageDataTracker.trackPageLoad()
  }, [])

  // JWT and context/aka siteKey from route
  const { jwt, context } = useParams()

  const POLLING_INTERVAL = 20000

  // FETCH extant jobs ONCE on initial render
  /* NB: eslint-disable-statement
   * due to React-proposed solution leading to infinite call
   * of setError.
   */
  useEffect(() => {
    getContentInventoryJobs(jwt, context)
      .then((results) => {
        // If number of jobs is zero, display "No Jobs message"
        setNoJobsFound(results.length === 0)
        setPastJobsData(results)
      })
      .catch((error) => {
        setError(error)
      }) // eslint-disable-next-line
  }, [])

  // Polling every 20 seconds with GET request
  // NB: ErrorStatus should not change if polling fails.
  useFileStatus(async () => {
    try {
      const results = await getContentInventoryJobs(jwt, context)
      setPastJobsData(results)
    } catch (error) {
      console.log('ERROR (polling GET request):', error)
    }
    //@ts-ignore
  }, [POLLING_INTERVAL])

  function setError(error: any) {
    if (error.response.data?.errors) {
      setErrorInfo({
        ...errorInfo,
        title: error.response.data.errors[0].title,
        detail: error.response.data.errors[0].detail,
      })
      setErrorModalVisible(true)
    } else if (error.response?.status === 500) {
      setErrorInfo({
        ...errorInfo,
        title: '500 Internal Server Error',
        detail:
          'If this problem persists, contact your Consulting Services representative.',
      })
      setErrorModalVisible(true)
    } else {
      setErrorInfo({
        ...errorInfo,
        title: 'Could not connect to the Content Reporting service',
        detail:
          'If this problem persists, contact your Consulting Services representative.',
      })
      setErrorModalVisible(true)
    }
  }

  async function handleRequestClick() {
    // POST request to make content inventory request
    try {
      await requestContentInventory(jwt, context)
      // If it succeeds, jobs are non-zero
      setNoJobsFound(false)
      trackRequestClick()
    } catch (error) {
      setError(error)
    }

    // GET request to get extant content inventory requests
    try {
      const results = await getContentInventoryJobs(jwt, context)
      if (results) {
        setPastJobsData(results)
      }
    } catch (error) {
      setError(error)
    }
  }

  return (
    <GrapheneRoot palette="palette-light">
      {errorModalVisible && (
        <ModalError
          errorInfo={errorInfo}
          closeModal={() => {
            setErrorModalVisible(false)
          }}
        />
      )}
      <div id="main-page" className="main-app-container">
        <Stack spacing="32dp" data-main-container="1">
          <Header headerLevel={2} headerType="subPage">
            Request content reporting data or analyses
          </Header>

          <Paragraph type="font16">
            The content inventory is an .xlsx spreadsheet listing all published
            works in your site with all associated metadata for reporting and
            analysis.
          </Paragraph>

          <PrimaryButton
            label="Request content inventory"
            type="button"
            onClick={handleRequestClick}
          />

          <ContentInventoryJobsList pastJobsData={pastJobsData} />

          {noJobsFound && (
            <p className="no-reports-message">
              No content inventory jobs were run in the last 30 days.
            </p>
          )}
        </Stack>
      </div>
    </GrapheneRoot>
  )
}
