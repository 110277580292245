import { useEffect } from 'react'
import React from 'react'
import '../scss/scrivener-main.scss'
import { adjustFrameHeight } from '../utils'
import { Header, Paragraph, Stack } from '@elsevier/graphene'
import JobListing from './JobListing'

interface jobData {
  id: string
  attributes: {
    links: any
    requestDateTime: number
    fileName: string
    status: string
  }
}

interface jobsDataListProp {
  pastJobsData: jobData[]
}
/*
 */

export default function ContentInventoryJobsList({
  pastJobsData,
}: jobsDataListProp) {
  // Adjust frame height
  useEffect(() => {
    adjustFrameHeight()
  }, [pastJobsData])

  return (
    <Stack spacing="28dp">
      <Header headerLevel={3} headerType="subPage">
        Content inventory jobs
      </Header>
      <Paragraph type="font16">
        Jobs are deleted 30 days after request.
      </Paragraph>
      {/* TODO: Fully convert to Graphene <Table> if possible.
       * <Text> component is used for now to wrap Table element.
       * Documentation on <Table> is not readily available in
       * Latest graphene.
       */}
      <Paragraph type="font16">
        <div className="collection-table">
          <table className="table">
            <thead>
              <tr>
                <th className="file-name">File name</th>
                <th className="request-date">Request Date</th>
                <th className="request-status">Status</th>
              </tr>
            </thead>
            <tbody>
              {pastJobsData.map((job: jobData) => {
                const { requestDateTime, fileName, status } = job.attributes
                const jobId = job.id
                const jobUrl = job.attributes.links.results

                return (
                  <JobListing
                    key={jobId}
                    fileName={fileName}
                    status={status}
                    requestDateTime={requestDateTime}
                    jobUrl={jobUrl}
                  />
                )
              })}
            </tbody>
          </table>
        </div>
      </Paragraph>
    </Stack>
  )
}
