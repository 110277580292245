import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import '@elsevier/graphene/dist/style.css'
import ScrivenerUI from './components/ScrivenerUI'
import React from 'react'

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/:context/:jwt" element={<ScrivenerUI />} />
      </Routes>
    </Router>
  )
}

export default App
