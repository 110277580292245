export function adjustFrameHeight() {
  const preferredHeight = 835
  const mainPage = document.getElementById('main-page')
  const height = mainPage?.offsetHeight || preferredHeight
  window.parent.postMessage(
    {
      frameHeight: height < preferredHeight ? preferredHeight : height,
    },
    '*'
  )
}
