import React from 'react'
import { fromUnixTime, format } from 'date-fns'

interface JobInformation {
  requestDateTime: number
  jobUrl: string
  status: string
  fileName: string
}

export default function JobListing({
  jobUrl,
  requestDateTime,
  status,
  fileName,
}: JobInformation) {
  return (
    <tr>
      <td className="file-name">
        {jobUrl ? <a href={jobUrl}>{fileName}</a> : fileName}
      </td>
      <td className="request-date">
        {format(fromUnixTime(requestDateTime), 'MM-dd-yyyy h:mm')}
      </td>
      <td className="request-status">{status}</td>
    </tr>
  )
}
