//TO DO: Find out why graphene components fail TS error checking
import '../scss/modal.scss'
import { Paragraph, Icon, PrimaryButton } from '@elsevier/graphene'
import React from 'react'

interface errorModalProps {
  errorInfo: {
    title: string
    detail: string
  }
  closeModal: () => void
}

export default function ModalError({ errorInfo, closeModal }: errorModalProps) {
  return (
    <div className="error-modal-container">
      <div className="error-dialog">
        <div className="error-dialog-top-row">
          <button className="x-close" onClick={closeModal} aria-label="close">
            <Icon fill="interaction" name="delete" size={18} />
          </button>
        </div>
        <div className="error-dialog-middle-row">
          <div className="error-middle-inner-top-row">
            <div className="alert-icon">
              <Icon fill="error" name="alert-solid-square" size={32} />
            </div>
            <Paragraph type="font16">{errorInfo.title}</Paragraph>
          </div>

          <div className="error-middle-inner-bottom-row">
            <Paragraph type="font14">{errorInfo.detail}</Paragraph>
          </div>
        </div>
        <div className="modal-bottom-row">
          <PrimaryButton onClick={closeModal} label="Close" size="small" />
        </div>
      </div>
    </div>
  )
}
